<template>
    <div class="raw-materia-prize">
        <div class="daily-sales-report">
            <!-- 导出excel -->
            <exportDom :dom-id="'#statisticsDom'" :excel-name="'原材料价格调整'"></exportDom>
            <el-table
                id="statisticsDom"
                :data="tableData"
                border
                style="width: 100%"
            >
                <el-table-column
                    prop="flmc"
                    label="原材料分类"
                >
                </el-table-column>
                <el-table-column
                    prop="zlmc"
                    label="原材料子类"
                >
                </el-table-column>
                <el-table-column
                    prop="ggmc"
                    label="规格"
                >
                </el-table-column>
                <el-table-column
                    prop="gysmc"
                    label="供应商"
                >
                </el-table-column>
                <el-table-column
                    prop="zc"
                    label="场站"
                >
                </el-table-column>
                <el-table-column
                    label="价格调整次数"
                >
                    <template slot-scope="scope">
                        <div @click="showChild(scope.row)">
                            {{ scope.row.tzcs }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <rawMateriaPrizeChild v-if="showChildStatus" :child-data="childData" @closeChildAlert="closeChildAlert"></rawMateriaPrizeChild>
    </div>
</template>

<script>
import exportDom from './../salesStatistics/components/export';
import rawMateriaPrizeChild from './components/rawMateriaPrize/rawMateriaPrizeChild';
export default {
    data() {
        return {
            showChildStatus: false,
            tableData: [],
            childData: {},
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.salesanalysisDay();
        });
    },
    components: {
        rawMateriaPrizeChild,
        exportDom,
    },
    methods: {
        showChild(data) {
            this.showChildStatus = true;
            this.childData = data;
        },
        closeChildAlert() {
            this.showChildStatus = false;
        },
        salesanalysisDay() {
            this.$axios
                .get('/interfaceApi/report/materialanalysis/ycljgtz/flygys/year?date=')
                .then(res => {
                    this.tableData = res.list;
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.raw-materia-prize
    width 100%
    background #ffffff
    .daily-sales-report
        position relative
        height 100%
        top 0
        left 0
        bottom 0
        right 0
        background #ffffff
        padding .2rem
        .export
            margin-top 0
            margin-bottom .1rem
        .el-input
            width .95rem !important
            cursor pointer
            input
                border none
                background #EDF0F5
                font-size .18rem
                color #022782
                font-weight 700
                padding 0
                width .95rem
                cursor pointer
    .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed
        border-color #D7D7D7 !important
    .el-table__body-wrapper
        tr td:last-child
            color #317FC7
            cursor pointer
    .el-table
        th, td
            padding .1rem 0
            text-align center
            div
                text-align center
        thead
            tr:first-child th
                background #F6F8FC
                color #022782
            tr:nth-child(2) th
                background #F6F8FC
                color #022782
        .el-table__footer-wrapper td
            background none
            &:first-child
                background #FEF9E4
                div
                    color #823802 !important
            div
                color #C9661E

</style>